<template lang="pug">
.main-wrapper.debitos-creditos-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / Caixa / <b>Debitos Creditos</b>

        Dialog.dialog-cadastrar(header="Criar débito/crédito" :modal="true"
            :visible.sync="dialogCadastrar" @hide="resetModel()")

            form(@submit.prevent="handleSubmit()")
                .p-grid.p-fluid
                    .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_paciente.$error }")
                        BuscarPaciente(ref="buscarPaciente" label="Cliente" :hideCadastro="true" :cancelarPopup="true")
                        .feedback--errors(v-if='submitted && $v.model.cd_paciente.$error')
                            .form-message--error(v-if="!$v.model.cd_paciente.required") <b>Campo</b> obrigatório.

                    .p-col-6(:class="{ 'form-group--error': submitted && $v.model.cd_forma_pagamento.$error }")
                        label.form-label Forma de pagamento:
                        ProgressBar(v-if="waitingFormasPagamento" mode="indeterminate")
                        Dropdown(
                            v-model='model.cd_forma_pagamento'
                            :options='options.formasPagamento'
                            optionLabel='nm_forma_pagamento'
                            optionValue='id'
                            v-else)
                        .feedback--errors(v-if='submitted && $v.model.cd_forma_pagamento.$error')
                            .form-message--error(v-if="!$v.model.cd_forma_pagamento.required") <b>Campo</b> obrigatório.

                    .p-col-6(:class="{ 'form-group--error': submitted && $v.model.nr_valor.$error }")
                        label.form-label Valor:
                        .p-inputgroup
                            span.p-inputgroup-addon R$
                            span(style='flex: 1')
                                currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled(
                                    v-model='$v.model.nr_valor.$model'
                                    locale='pt-BR'
                                    :class="{ 'valor-negativo': model.nr_valor < 0 }"
                                    :auto-decimal-mode='true'
                                    @input="checkTipo()")
                        .feedback--errors(v-if='submitted && $v.model.nr_valor.$error')
                            .form-message--error(v-if="!$v.model.nr_valor.required") <b>Campo</b> obrigatório.
                            .form-message--error(v-if="!$v.model.nr_valor.minAbs") Valor mínimo: <b>R$ 0,01</b>.

                    .p-col-6(:class="{ 'form-group--error': submitted && $v.model.ie_tipo.$error }")
                        label.form-label Tipo:
                        Dropdown(
                            v-model='$v.model.ie_tipo.$model'
                            :options='options.tipo'
                            optionLabel='text'
                            optionValue='value'
                            dataKey='value'
                            @input='fixNrValor()')
                        .feedback--errors(v-if='submitted && $v.model.ie_tipo.$error')
                            .form-message--error(v-if="!$v.model.ie_tipo.required") <b>Campo</b> obrigatório.

                    .p-col-6.mb-2(:class="{ 'form-group--error': submitted && $v.model.ie_status.$error }")
                        label.form-label Status:
                        Dropdown(
                            v-model='$v.model.ie_status.$model'
                            :options='options.status'
                            optionLabel='text'
                            optionValue='value'
                            dataKey='value')
                        .feedback--errors(v-if='submitted && $v.model.ie_status.$error')
                            .form-message--error(v-if="!$v.model.ie_status.required") <b>Campo</b> obrigatório.

                    .p-col-12.ta-center.mt-4
                        ProgressSpinner.small-spinner(v-if="waitingCadastrar" strokeWidth="6")
                        Button(v-else label="Salvar" icon="jam jam-plus" type="submit")

        Dialog.dialog-vizualizar(:visible.sync="dialogVizualizar"
            :header="(dialogVizualizarData.ie_tipo === 'C') ? 'Crédito' : 'Débito'" :modal="true")

            .p-grid.p-fluid(v-if="dialogVizualizarData" style="padding-bottom:0")
                .p-col-12
                    label.form-label Cliente:
                    InputText.full-opacity(disabled v-model="dialogVizualizarData.nm_paciente_f")

                .p-col-12
                    label.form-label Informações:
                    InputText.full-opacity(disabled v-model="dialogVizualizarData.ds_informacoes")

                .p-col-6
                    label.form-label Forma de pagamento:
                    InputText.full-opacity(disabled v-model="dialogVizualizarData.cd_forma_pagamento.nm_forma_pagamento")

                .p-col-6
                    label.form-label Valor:
                    .p-inputgroup
                        span.p-inputgroup-addon R$
                        span(style='flex: 1')
                            currency-input.input-adicionar.p-inputnumber-input.p-inputtext.p-component.p-filled.full-opacity(
                                disabled
                                v-model='dialogVizualizarData.nr_valor'
                                locale='pt-BR'
                                :class="{ 'valor-negativo': dialogVizualizarData.nr_valor < 0 }"
                                :auto-decimal-mode='true')

                .p-col-6
                    label.form-label Tipo:
                    InputText.full-opacity(disabled :value="dialogVizualizarData.ie_tipo === 'C' ? 'Crédito' : 'Débito'")

                .p-col-6
                    label.form-label Status:
                    InputText.full-opacity(disabled :value="getStatusName(dialogVizualizarData.ie_status)")

                .p-col-12.mt-2.mb-2(v-if="dialogVizualizarData.id")
                    .p-grid
                        .p-col-12.p-md-6.aux-2
                            span <b>Usuário criação: </b> {{ dialogVizualizarData.nm_usuario_cri }}
                        .p-col-12.p-md-6.aux-2
                            span <b>Data da criação: </b> {{ dialogVizualizarData.dt_data_f }}
                        .p-col-12.p-md-6.aux-2
                            span <b>Usuário edição: </b> {{ dialogVizualizarData.nm_usuario_edi }}
                        .p-col-12.p-md-6.aux-2
                            span <b>Data da atualização: </b> {{ dialogVizualizarData.dt_atualizado_f }}

            .mt-4.mb-2(v-if="dialogVizualizarData.ie_status === 'P'" style="display: flex; justify-content: center;")
                ProgressSpinner.small-spinner(v-if="waitingVizualizar" strokeWidth="6")
                div(v-else)
                    Button.mr-1(
                        label="Aprovar" icon="jam jam-plus"
                        @click="isConfirmAprovar=true; dialogConfirm=true"
                        )
                    Button.p-button-danger.ml-1(
                        label="Recusar" icon="jam jam-close"
                        @click="isConfirmAprovar=false; dialogConfirm=true"
                        )

        Dialog.dialog-confirm(:showHeader="false" :visible.sync="dialogConfirm"
            :modal="true" :contentStyle="{overflowY: 'auto !important'}")

            .ta-center.mt-2
                span  Deseja mesmo <b>{{ isConfirmAprovar ? 'aprovar' : 'recusar' }} </b>
                    | o <b>{{ (dialogVizualizarData.ie_tipo === 'C') ? 'crédito' : 'débito' }} </b>?

            .ta-right.mt-4
                Button(v-show="false")
                Button.p-button-text.mr-2(label="Não" icon="pi pi-times" @click="dialogConfirm=false")
                Button.p-button-text(label="Sim" icon="pi pi-check" @click="onDialogConfirm(); dialogConfirm=false")

        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid.p-align-end
                .p-col-12.p-md-3
                    label.form-label CPF:
                    .p-inputgroup
                        InputMask(
                            v-model='filters.nr_cpf'
                            mask='999.999.999-99'
                            :autoClear='false'
                            @keydown.enter='applyFilters()'
                            @blur='checkEmptyField("nr_cpf")')
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-6
                    label.form-label Nome:
                    .p-inputgroup
                        InputText(
                            v-model='filters.nm_pessoa_fisica'
                            @keyup.enter.native='applyFilters()'
                            @input='checkEmptyField("nm_pessoa_fisica")')
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label Origem:
                    MultiSelect(
                        v-model='filters.ie_origem'
                        :options='options.ie_origem'
                        dataKey='value'
                        optionLabel='label'
                        optionValue='value'
                        @change='checkChangeOrigem()')

                .p-col-12.p-md-3
                    label.form-label Tipo:
                    Dropdown(
                        v-model='filters.ie_tipo'
                        :options='options.tipo'
                        dataKey='id'
                        optionLabel='text'
                        optionValue='value'
                        @change='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label Status:
                    MultiSelect(
                        v-model='filters.ie_status'
                        :options='options.status'
                        optionLabel='text'
                        optionValue='value'
                        @hide='checkChangeStatus()')

                .p-col-6.p-md-3
                    label.form-label Data inicial:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_inicial' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='filters.dt_final'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_inicial = null; applyFilters()')

                .p-col-6.p-md-3
                    label.form-label Data final:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_final' dateFormat="dd/mm/yy" :locale="ptbr" :minDate='filters.dt_inicial'
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_final = null; applyFilters()')

        .ta-right.mb-2
            Button(label='Adicionar' icon="jam jam-plus" @click="dialogCadastrar=true")

        


        .mb-1.selecionar-items-panel
            div
                InputSwitch.mr-2(v-model='mostrarSelecionados')
                label.label-mostrar-selecionados(@click="mostrarSomenteSelecionados") Mostrar somente selecionados

            div
                p.text-soma <b>Soma:</b> {{ formatPrice(sum) }}
                p.text-soma <b>Selecionados:</b> {{ selecionados.length }}
                Button.p-button-danger.ml-1(
                    v-tooltip.top="'Limpar seleção'"
                    :disabled='!selecionados.length'
                    icon='jam jam-trash'
                    @click="dialogConfirmacao = true")
        ConfirmDialog(
			:display="dialogConfirmacao"
			@confirmed="cancelarSelecao"
			@close="dialogConfirmacao = false")
            template(#text-content)
                span Deseja mesmo <b>cancelar a seleção</b> de <b>todos</b> os itens?

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)

            Panel.datatable(header='Lista de Debitos Creditos')
                Paginator.mb-1(v-if="!mostrarSelecionados" :rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                DataTable.datatable(:value="listaDinamica")
                    Column(headerStyle='width: 5%' bodyStyle='text-align:center')
                        template(#header)
                            Checkbox(v-if='!mostrarSelecionados' v-model="selecionarTodos" binary)
                        template(#body='props')
                            Checkbox(v-model='selecionados' :value='props.data' :id='props.id')
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center' field='dt_data_f' header='Data')
                    Column(headerStyle='width: 18%; text-align: left' header='Cliente')
                        template(#body='props')
                            span {{ props.data.cd_paciente.nm_pessoa_fisica }}
                            span.d-block(style="font-size:10px") CPF: {{ props.data.cd_paciente.nr_cpf_f }}
                    Column(headerStyle='width: 18%;' field='ds_informacoes' header='Informações')
                    Column(headerStyle='width: 15%;' field='ds_origem_valor' header='Origem')
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center' header='Status')
                        template(#body='props')
                            .cell.pe(v-if="props.data.ie_status === 'P'") Pendente
                            .cell.ap(v-if="props.data.ie_status === 'A'") Aprovado
                            .cell.ca(v-if="props.data.ie_status === 'C'") Cancelado
                    Column(headerStyle='width: 15%;' field='cd_forma_pagamento.nm_forma_pagamento' header='Forma de pagamento')
                    Column(headerStyle='width: 10%;' field='nr_valor' header='Valor')
                        template(#body='props')
                            .cell(:class="{ d: 0 >= props.data.nr_valor, c: 0 < props.data.nr_valor }")
                                span {{ formatPrice(props.data.nr_valor) }}
                    Column(headerStyle='width: 9%;' header='Ações')
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded(
                                    v-tooltip.top="'Vizualizar'" icon="pi pi-external-link"
                                    @click='openVizualizar(props.data)'
                                )

                Paginator(v-if="!mostrarSelecionados" :rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
        .mb-1.selecionar-items-panel(v-if="shouldShowBottomPanel")
            div
            div
                p.text-soma <b>Soma:</b> {{ formatPrice(sum) }}
                p.text-soma <b>Selecionados:</b> {{ selecionados.length }}
                Button.p-button-danger.ml-1(
                    v-tooltip.top="'Limpar seleção'"
                    :disabled='!selecionados.length'
                    icon='jam jam-trash'
                    @click="dialogConfirmacao = true")
</template>

<style lang="scss">
.debitos-creditos-listar {
    .dataview {
        @media all and (min-width: 577px) {
            display: none;
        }
    }
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }

        .cell {
            padding: 15px 0;
            text-align: center;

            &.c { background-color: #90cffd; }
            &.d { background-color: #fc9595; }
            &.ap { background-color: #e4f8e1; }
            &.ca { background-color: #fc9595; }
            &.pe { background-color: #fff9b1; }
        }
    }
    .dialog-vizualizar,
    .dialog-cadastrar {
        width: 700px;
        max-width: 98%;
    }
    .dialog-confirm {
        width: 300px;
        .p-dialog-content {
            padding-bottom: 6px;
        }
        .p-button.p-button-text {
            background-color: transparent;
            color: #2196F3;
            border-color: transparent;

            &:hover {
                border-color: #2196F3;
            }
        }
    }
    .small-spinner{
        width: 29px;
        height: auto;
    }
    .input-adicionar {
        color: #4372ae;
        text-align: right;
        font-weight: 700;
    }
    .full-opacity {
        opacity: 1 !important;
    }
    /* Selecionar itens e selecionar todos  */
    .label-mostrar-selecionados {
			vertical-align: super;
			font-size: 12px;
			font-weight: 550;
	}
    .text-soma {
			margin-bottom: 4px;
			margin-right: 10px;
			display: inline-block;
			background-color: #faf3dd;
			box-shadow: 0 1px 2px #ddd;
			padding: 6px 10px;
	}
    .selecionar-items-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.p-tooltip {
    max-width: none;
}
</style>

<script>
    import Button from "primevue/button"
    import Calendar from 'primevue/calendar'
    import Column from "primevue/column"
    import DataTable from "primevue/datatable"
    import DataView from "primevue/dataview"
    import Dialog from "primevue/dialog"
    import Dropdown from 'primevue/dropdown'
    import Checkbox from 'primevue/checkbox'
    import InputSwitch from 'primevue/inputswitch'
    import InputMask from 'primevue/inputmask'
    import InputText from "primevue/inputtext"
    import MultiSelect from 'primevue/multiselect'
    import Paginator from "primevue/paginator"
    import Panel from "primevue/panel"
    import ProgressBar from "primevue/progressbar"
    import ProgressSpinner from "primevue/progressspinner"
    import Tooltip from "primevue/tooltip"

    import ConfirmDialog from "@/components/CustomComponents/ConfirmDialog"

    import BuscarPaciente from "../Paciente/BuscarPaciente"
    import {Caixas, DebitoCredito, DominioValor} from "./../../middleware"
    import wsConfigs from "./../../middleware/configs"
    import {pCalendarLocale_ptbr} from './../../utils'
    import {required} from "vuelidate/lib/validators"
    import moment from "moment"

    export default {
        created() {
            if (this.$route.query.nr_cpf) this.filters.nr_cpf = this.$route.query.nr_cpf.replace(/\D/g, '')
            let status = this.$route.query.ie_status?.charAt(0).toUpperCase()
            let origem = this.$route.query.ei_origem?.charAt(0).toUpperCase()
            if(origem){
                this.filter.ie_origem = [origem]
                this.previousOrigem = [origem]
            }
            if (status) {
                this.filters.ie_status = [status]
                this.previousStatus = [status]
            }
            this.getDominios()
            this.applyFilters()
            this.getFormasPagamento()
        },
        components: {Button, BuscarPaciente, Calendar, Column, DataTable, DataView, Dialog, Dropdown,
            Checkbox, InputSwitch, InputMask, InputText, MultiSelect, Paginator, Panel, ProgressBar, ProgressSpinner, Tooltip, ConfirmDialog},
        directives: {tooltip: Tooltip},
        data() {
            return {
                model: {
                    nr_valor: 0,
                    ie_tipo: "C",
                    ie_status: "P",
                    cd_forma_pagamento: 1,
                    cd_paciente: null,
                },
                list: [],
                submitted: false,
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingFormasPagamento: false,
                waitingCadastrar: false,
                dialogCadastrar: false,
                dialogVizualizar: false,
                dialogVizualizarData: {
                    cd_paciente: {},
                    cd_forma_pagamento: {},
                },
                waitingVizualizar: false,
                dialogConfirm: false,
                isConfirmAprovar: true,
                options: {
                    ie_origem: [],
                    tipo: [
                        {text: "TODOS", value: ""},
                        {text: "Crédito", value: "C"},
                        {text: "Débito", value: "D"},
                    ],
                    status: [
                        {text: "Pendente", value: "P"},
                        {text: "Aprovado", value: "A"},
                        {text: "Cancelado", value: "C"},
                    ]
                },
                filters: {
                    nm_pessoa_fisica: "",
                    nr_cpf: "",
                    ie_tipo: "",
                    ie_status: [],
                    dt_inicial: null,
                    dt_final: null,
                    ie_origem: [],
                },
                previousStatus: [],
                previousOrigem: [],
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0,
                },

                // Selecionar itens e selecionar todos
                mostrarSelecionados: false,
                selecionarTodos:false,
                selecionados: [],
                dialogConfirmacao: false
            }
        },
        computed: {
            listaDinamica() {
                return this.mostrarSelecionados ? this.selecionados : this.list
            }, 
            sum() {
				if (!this.selecionados) return 0
				return this.selecionados.reduce((total, item) => total + parseFloat(item.nr_valor), 0)
			},
            shouldShowBottomPanel(){
                if(this.mostrarSelecionados){
                    return !this.waiting && this.selecionados.length > 12
                }
                return !this.waiting
            }
        },
        watch: {
            selecionarTodos: function(newVal){
                if(newVal){
                    this.selecionados = this.list
                }else{
                    this.selecionados = []
                }
            }
        },
        validations () {
            const minAbs = (val) => Math.abs(val) > 0
            let v = {
                model: {
                    cd_paciente: { required },
                    cd_forma_pagamento: { required },
                    nr_valor: { required, minAbs },
                    ie_tipo: { required },
                    ie_status: { required },
                }
            }
            return v
        },
        methods: {
            getDominios() {
                DominioValor.findAll({ds_mnemonico: ['ORIGEM_DEBITO_CREDITO']}).then(response => {
                    if (([200,201,204]).includes(response.status)) {
                        this.options.ie_origem = response.data['ORIGEM_DEBITO_CREDITO'].valores.map(item => ({ value: item.ie_valor, label: item.ds_valor }))
                    }
                })
            },
            handleSubmit() {
                this.model.cd_paciente = this.$refs.buscarPaciente.cd_pessoa_fisica

                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return

                this.waitingCadastrar = true
                DebitoCredito.save(this.model).then(response => {
                    this.waitingCadastrar = false
                    if ([200, 201, 204].includes(response.status)) {
                        this.$toast.success("Débito/Crédito salvo com sucesso.")
                        this.dialogCadastrar = false
                        this.resetModel()
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            getList(params) {
                this.waiting = true;
                return DebitoCredito.findAll(params).then((response) => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        let list = response.data.results
                        list.forEach((debitoCredito) => {
                            debitoCredito.dt_data_f = moment(debitoCredito.dt_data).format("DD/MM/YYYY HH:mm:ss")
                            if (debitoCredito.dt_atualizado) {
                                debitoCredito.dt_atualizado_f = moment(debitoCredito.dt_atualizado)
                                    .format("DD/MM/YYYY HH:mm")
                            }
                            debitoCredito.cd_paciente.nr_cpf_f = debitoCredito.cd_paciente.nr_cpf?.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'
                            ) || ""
                        })
                        this.list = list
                    }
                    this.waiting = false
                })
            },
            applyFilters(page) {
                this.paginator.page = page || 1
                let params = {
                    paginacao: true,
                    page: this.paginator.page,
                    per_page: this.paginator.per_page,
                }
                if (this.$route.query.pg != this.paginator.page) {
                    this.$router.replace({query: {pg: this.paginator.page}})
                }
                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key]) {
                        if (key === 'nr_cpf') params.nr_cpf = this.filters[key].replace(/(\.|-|_)/g, '')
                        else params[key] = this.filters[key]
                    }
                })
                this.getList(params)
            },
            getFormasPagamento() {
                this.waitingFormasPagamento = true
                Caixas.getFormasPagamento().then(response => {
                    this.waitingFormasPagamento = false
                    if (response.status === 200) {
                        this.options.formasPagamento = response.data
                    }
                })
            },
            openVizualizar(data) {
                let model = Object.assign(data, {})
                const cpf = model.cd_paciente.nr_cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') || ""
                model.nm_paciente_f = `${model.cd_paciente.nm_pessoa_fisica} (CPF: ${cpf})`
                model.nr_valor = parseFloat(`${model.nr_valor}`)
                this.dialogVizualizarData = model
                this.dialogVizualizar = true
            },
            onDialogConfirm() {
                let dataSend = {
                    id: this.dialogVizualizarData.id,
                    ie_status: this.isConfirmAprovar ? "A" : "C"
                }

                this.waitingVizualizar= true
                DebitoCredito.save(dataSend).then(response => {
                    this.waitingVizualizar = false
                    if ([200, 201, 204].includes(response.status)) {
                        this.$toast.success("Salvo com sucesso.")
                        this.dialogVizualizarData.ie_status = dataSend.ie_status
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            getStatusName(status) {
                if (status === "P") return "Pendente"
                if (status === "A") return "Aprovado"
                return "Cancelado"
            },
            checkEmptyField(key) {
                !!this.filters[key] || this.applyFilters()
            },
            checkTipo() {
                this.model.ie_tipo = (this.model.nr_valor < 0) ? "D" : "C"
            },
            checkChangeStatus() {
                if (JSON.stringify(this.filters.ie_status.sort()) !== JSON.stringify(this.previousStatus.sort())){
                    this.previousStatus = [...this.filters.ie_status]
                    this.applyFilters()
                }
            },
            checkChangeOrigem() {
                if (JSON.stringify(this.filters.ie_origem.sort()) !== JSON.stringify(this.previousOrigem.sort())){
                    this.previousOrigem = [...this.filters.ie_origem]
                    this.applyFilters()
                }
            },
            fixNrValor () {
                if (this.model.ie_tipo === 'C') {
                    this.model.nr_valor = Math.abs(this.model.nr_valor)
                } else {
                    this.model.nr_valor = -Math.abs(this.model.nr_valor)
                }
            },
            formatPrice(val) {
                return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(val)
            },
            onPage(ev) {
                this.applyFilters(ev.page + 1)
            },
            resetModel() {
                this.model = {
                    nr_valor: 0,
                    ie_tipo: "C",
                    ie_status: "P",
                    cd_forma_pagamento: 1,
                    cd_paciente: null,
                }
                this.submitted = false
            },
            // Selecionar itens e selecionar todos
            mostrarSomenteSelecionados() {
                this.mostrarSelecionados = !this.mostrarSelecionados
            },
            cancelarSelecao() {
                this.selecionarTodos = false
                this.mostrarSelecionados = false
                this.selecionados = []
                this.dialogConfirmacao = false
            }
            
        },
    }
</script>
